.title {
  margin-top: 1rem;
}
.project-image {
  position: relative;
}

.project-image img {
  height: 100%;
  width: auto;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(133, 133, 133, 0.5);
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 15%;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.logo {
  width: 50px;
  height: auto;
  margin: 0 auto;
}
.iconlist {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.links__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.link {
  color: white;
  padding: 7px 12px;
  border-radius: 5px;
  background-color: #375263;
  text-decoration: none;
  font-weight: 500;
}

.link:hover {
  color: var(--title-color-dark);
}

.category__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
