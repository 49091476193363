.work__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: var(--mb-2);
  padding: 0 1rem;
}

.work__item {
  cursor: pointer;
  color: var(--title-color);
  padding: 0.25rem 0.75rem;
  font-weight: var(--font-medium);
  border-radius: 0.5rem;
  text-transform: capitalize;
  transition: background-color 0.3s ease;
}

.work__item:hover {
  background-color: var(--bg);
  color: var(--container-color);
}

.work__container {
  display: grid;
  gap: 2rem;
  justify-content: center;
}

.work__card {
  border-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  border-radius: 1rem;
}

.work__card:hover {
  border-color: var(--bg);
  transition: transform 0.3s ease;
  transform: scale(1.03);
}

.work__img {
  width: 600px;
  border-radius: 0.5rem;
  margin-bottom: var(--mb-1);
}

.work__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-semi-bold);
}

.work__button {
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.work__button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.work__button:hover .work__button-icon {
  transform: translateX(0.25rem);
}

.active-work {
  background-color: var(--bg);
  color: var(--container-color);
}
.work__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.work__info__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.work__img__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.work__tech-img {
  width: 30px;
  height: 30px;
  border-radius: 0.5rem;
}

@media screen and (max-width: 992px) {
  .work__container {
    gap: 1.25rem;
  }
  .work__card {
    padding: 1rem;
  }
  .work__img {
    margin-bottom: 0.75rem;
  }
}

@media screen and (max-width: 768px) {
  .work__container {
    grid-template-columns: max-content;
  }
}

@media screen and (max-width: 576px) {
  .work__container {
    grid-template-columns: 1fr;
  }

  .work__img {
    width: 100%;
  }
  .work__img__wrapper {
    display: none;
  }
}

@media screen and (max-width: 350px) {
  .work__item {
    font-size: var(--small-font-size);
  }

  .work__filters {
    column-gap: 0.25rem;
  }
}

.techs {
  color: var(--title-color);
}
