.notfound_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4.5rem;
  align-items: center;
  min-height: calc(100vh - 210px - 4.5rem);
  background-color: #f5f5f5;
  padding: 0 1rem;
  box-sizing: border-box;
}

.notfound_container h1 {
  margin-bottom: 1rem;
  text-align: center;
}

.notfound_container a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  text-align: center;
  background-color: var(--bg);
  padding: 7px 10px;
  border-radius: 5px;
}
